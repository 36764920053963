// App.js
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity,  ImageBackground,Image } from 'react-native';

const App = () => {
  const [activeSection, setActiveSection] = React.useState('home');

  const handleNavigation = (section) => {
    setActiveSection(section);
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('./assets/background.jpg')}
        style={styles.headerBackground}
        resizeMode="cover"
      >
      <View style={styles.overlay} />
      <View style={styles.header}>
        <Image source={require('./assets/logo.png')} style={styles.logo} />
        <Text style={styles.companyName}>Divisol Consultants LLP</Text>
      </View>
      </ImageBackground>
     
      <View style={styles.navbar}>
        <TouchableOpacity onPress={() => handleNavigation('home')}>
          <Text style={[styles.navItem, activeSection === 'home' && styles.activeNavItem]}>Home</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleNavigation('about')}>
          <Text style={[styles.navItem, activeSection === 'about' && styles.activeNavItem]}>About Us</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleNavigation('contact')}>
          <Text style={[styles.navItem, activeSection === 'contact' && styles.activeNavItem]}>Contact</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.section}>
        {activeSection === 'home' && <Home />}
        {activeSection === 'about' && <AboutUs />}
        {activeSection === 'contact' && <Contact />}
      </View>
    </View>
  );
};

const Home = () => {
  return (
    <View style={styles.sectionContent}>
      <Image source={require('./assets/home_page.png')} style={styles.image} />
      <Text style={styles.highlightedText}>We are Divisol Consultants LLP!</Text>
      <Text>We offer solutions to evrything needed!!</Text>
      <Text>Be it construction, manpower or outsourcing!!</Text>
      {/* Add more content as needed */}
    </View>
  );
};

const AboutUs = () => {
  return (
    <View style={styles.sectionContent}>
      <Text style={styles.highlightedText}>About Us</Text>
      <Text>Divisol Consultants LLP is firm started in July 2023 under the leadership of Lalanjee Jha, a veteran in industry with a vision to give solutions to the gap between employer and employee market</Text>
    </View>
  );
};

const Contact = () => {
  return (
    <View style={styles.sectionContent}>
      <Text style={styles.highlightedText}>For more information, reach out to us at:</Text>
      <Text>director@divisolconsultants.com</Text>
      <Text>+919968841051</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(247, 247, 247, 1)'
  },
  headerBackground: {
    width: '100%',
    height: 220,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(255, 165, 0, 0.5)', // Orange with 50% opacity
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10
  },
  logo: {
    width: 200,
    height: 200,
    marginRight: 10,
    borderRadius:100,
    
  },
  companyName: {
    color: '#000',
    fontSize: 50,
    fontWeight: 'bold',
  },
  navbar: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#270949',
    paddingVertical: 10,
  },
  navItem: {
    color: '#fff',
    fontSize: 18,
  },
  activeNavItem: {
    fontWeight: 'bold',
  },
  section: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 20,
  },
  highlightedText: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    color: '#270949', // Highlighted text color
  },
});

export default App;
